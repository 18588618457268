import React, {useState, useEffect} from 'react';
import Quagga from 'quagga';

const BarcodeScanner = ({onScan, stopScan}) => {
    useEffect(() => {
        let scannerIsStopped = false;

        const handleScan = (result) => {
            if (!scannerIsStopped && result && result.codeResult && result.codeResult.code) {
                onScan(result.codeResult.code);
            }
        };

        Quagga.init({
            inputStream: {
                name: 'Live',
                type: 'LiveStream',
                target: document.querySelector('#barcode-scanner'),
                constraints: {
                    facingMode: 'environment',
                },
            },
            decoder: {
                readers: ['ean_reader', 'upc_reader'],
            },
        }, (err) => {
            if (err) {
                console.error('Error initializing Quagga:', err);
                return;
            }
            Quagga.start();

            Quagga.onDetected(handleScan);
        });

        return () => {
            scannerIsStopped = true;
            Quagga.stop();
        };
    }, [onScan, stopScan]);

    return (
        <div id="barcode-scanner" style={{width: '100%', height: '100%'}}/>
    );
};

export default BarcodeScanner;
