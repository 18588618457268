const response_interceptor = (instance) => {

    instance.interceptors.response.use(resp => resp, async error => {
        if (error) {
            console.log("Interceptor Error", error)
        }

        return Promise.reject(error);
    });
}

export default response_interceptor;
