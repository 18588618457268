import axios from 'axios';
import response_interceptor from "../interceptors/axios_response_interceptor";

const ENDPOINT = process.env.REACT_APP_V3_ENDPOINT;

async function get(url, params) {
    try {
        const instance = await getInstance();
        if (params) {
            return await instance.get(ENDPOINT + url, {params: {...params}});
        }
        return await instance.get(ENDPOINT + url);
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function put(url, data) {
    try {
        const instance = await getInstance();
        return await instance.put(ENDPOINT + url, {...data});
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function post(url, params) {
    try {
        const instance = await getInstance();
        return await instance.post(ENDPOINT + url, {...params});
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function postEncoded(url, params) {
    try {
        const instance = await getInstance();
        if (params) {
            return await instance.post(ENDPOINT + url, new URLSearchParams({...params}));
        }
        return await instance.post(ENDPOINT + url);
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DELETE(url) {
    try {
        const instance = await getInstance();
        return await instance.delete(ENDPOINT + url);
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function getInstance() {
    let access_token = sessionStorage.getItem('@auth_token');

    let instance = axios.create({
        baseURL: ENDPOINT,
        headers: {
            'x-auth-token': access_token
        }
    });

    response_interceptor(instance)
    return instance
}

export const API = {
    get,
    post,
    put,
    postEncoded,
    DELETE
}
