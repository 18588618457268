import React from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../utils/contexts/dish-context";
import DishNameInput from "../components/CalculateImpact/DishNameInput";
import HistoryOfSearchedDishes from "../components/CalculateImpact/History/HistoryOfSearchedDishes";
import Dish from "../components/CalculateImpact/Dish/Dish";
import NutritionalForm from "../components/CalculateImpact/Dish/Nutritional/NutritionalForm";
import IngredientsWeightForm from "../components/CalculateImpact/Dish/IngredientsWeightForm";
import {useNavigate} from "react-router-dom";
import Button from "../components/UI/Button";

const CalculateImpact = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {dish, weightModalStatus, nutritionalModalStatus, setDish} = useDish();

    const handleGdo = () => {
        setDish(undefined);
        navigate('/gdo');
    }

    return (
        <div>
            <div className="fixed top-18 right-0 items-center justify-center px-2 py-3.5">
                <Button onClick={handleGdo}>GDO</Button>
            </div>

            <div className="p-2 bg-blue-100">
                <div className="text-lg font-bold uppercase">{t('calculate_impact.title')}</div>
                <DishNameInput/>
                <HistoryOfSearchedDishes/>
            </div>
            {dish && <Dish/>}
            {dish && weightModalStatus &&
                <IngredientsWeightForm/>
            }
            {dish && nutritionalModalStatus &&
                <NutritionalForm/>
            }
        </div>
    );
};

export default CalculateImpact;
