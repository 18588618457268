import React from 'react';
import Ingredients from "../Ingredients/Ingredients";
import DishDetails from "./DishDetails";
import Diets from "./Diet/Diets";
import Allergens from "./Allergens/Allergens";
import Impact from "./Impact/Impact";
import DishActions from "./DishActions";
import {useLocation} from "react-router-dom";

const Dish = () => {

    const location = useLocation();
    const showItems = () => {
        switch (location.pathname) {
            case '/gdo':
                return false;
            default:
                return true;
        }
    };

    return (
        <div className="p-2">
            <DishDetails/>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <Diets/>
                <Allergens/>
                <Impact/>
            </div>
            <Ingredients/>
            {showItems() && <DishActions/>}
        </div>
    );
};

export default Dish;
