import {API} from "./api_config";

const ENDPOINT = process.env.REACT_APP_V3_ENDPOINT;

async function doPostLogin(email, password) {
    let response = await API.postEncoded('envimpact/login', {email, password});
    if (response) return response.data;
}

async function doGetWhoAmI() {
    let response = await API.get('envimpact/whoami');
    if (response) return response.data;
}

export {
    doPostLogin,
    doGetWhoAmI,
}
