import axios from "axios";
import {API} from "./api_config";

const MYCIA_ENDPOINT = process.env.REACT_APP_METIDE_ENDPOINT;

async function doPostTestDish(pia_nome, lang = 'en') {
    return await API.postEncoded(`envimpact/restaurants/1/courses/1/dishes?lang=${lang}`, {pia_nome: pia_nome});
}

async function doGetDishes() {
    return await API.get('envimpact/restaurants/1/courses/1/dishes/');
}

async function doGetDish(id, lang= 'en') {
    return await API.get(`envimpact/restaurants/1/courses/1/dishes/${id}?lang=${lang}`);
}

async function doGetDiets(dish_id) {
    let response = await axios.get(MYCIA_ENDPOINT + `piatto/${dish_id}/eidiete`);
    if(response) return response.data;
}

async function doGetAllergens(dish_id) {
    let response = await axios.get(MYCIA_ENDPOINT + `piatto/${dish_id}/eiallergeni`);
    if(response) return response.data;
}

async function doGetIngredientsImpact(dish_id, lang = 'en') {
    return await API.get(`envimpact/restaurants/1/courses/1/dishes/${dish_id}/impact?lang=${lang}`);
}

async function doGetNutritionalValuesOfDish(dish_id) {
    let response = await axios.get(MYCIA_ENDPOINT + `piatto/${dish_id}/einutrienti`);
    if(response) return response.data;
}

async function doPutDish(id, ingredients, lang = 'en') {
    return await API.put(`envimpact/restaurants/1/courses/1/dishes/${id}/ingredients?lang=${lang}`, {ingredients: ingredients}, {headers: {"Content-Type": "application/json"}});
}

async function doDeleteDish(id) {
    return await API.DELETE(`envimpact/restaurants/1/courses/1/dishes/${id}`);
}

export {
    doPostTestDish,
    doGetDishes,
    doGetDish,
    doGetDiets,
    doGetAllergens,
    doGetIngredientsImpact,
    doGetNutritionalValuesOfDish,
    doPutDish,
    doDeleteDish,
}