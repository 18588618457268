import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "../../App";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import CalculateImpact from "../../pages/CalculateImpact";
import {DishProvider} from "../../utils/contexts/dish-context";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../../pages/NotFound";
import CalculateGdo from "../../pages/CalculateGdo";
import OffDataDisplay from "../CalculateGdo/OffDataDisplay";

const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App/>}>
                    <Route index element={<Home/>}/>
                    <Route path='impact/calculate' element={
                        <ProtectedRoute>
                            <DishProvider>
                                <CalculateImpact/>
                            </DishProvider>
                        </ProtectedRoute>
                    }/>
                    <Route path='gdo' element={
                        <ProtectedRoute>
                            <DishProvider>
                                <CalculateGdo/>
                            </DishProvider>
                        </ProtectedRoute>
                    }/>
                    <Route path='off_data' element={
                        <ProtectedRoute>
                            <DishProvider>
                                <OffDataDisplay/>
                            </DishProvider>
                        </ProtectedRoute>
                    }/>
                    <Route path='login' element={<Login/>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Routing;
