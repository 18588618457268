import React from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../utils/contexts/dish-context";
import Dish from "../components/CalculateImpact/Dish/Dish";
import NutritionalForm from "../components/CalculateImpact/Dish/Nutritional/NutritionalForm";
import IngredientsWeightForm from "../components/CalculateImpact/Dish/IngredientsWeightForm";
import Button from "../components/UI/Button";
import {useNavigate} from "react-router-dom";
import BarcodeInput from "../components/CalculateGdo/BarcodeInput";

const CalculateGdo = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const {dish, weightModalStatus, nutritionalModalStatus, setDish} = useDish();

    const handleImpact = () => {
        setDish(undefined);
        navigate('/impact/calculate');
    }

    return (
        <div>
            {/*<div className="fixed top-18 right-0 items-center justify-center px-2 py-3.5">*/}
            {/*    <Button onClick={handleImpact}>IMPACT</Button>*/}
            {/*</div>*/}
            <div className="p-2 bg-blue-100">
                <div className="text-lg font-bold uppercase">{t('gdo.title')}</div>
                <BarcodeInput/>
            </div>
            {dish && <Dish/>}
            {dish && weightModalStatus &&
                <IngredientsWeightForm/>
            }
            {dish && nutritionalModalStatus &&
                <NutritionalForm/>
            }


        </div>
    );
};

export default CalculateGdo;
