import axios from "axios";
import {API} from "./api_config";

const ENDPOINT = process.env.REACT_APP_V3_ENDPOINT;

async function doGetCompoundIngredients(ingredient_id, lang = 'en') {
    let response = await axios.get(ENDPOINT + `foods/${ingredient_id}?ingredienti=1&lang=${lang}`);
    if(response) return response.data;
}

async function doGetIngredientImpactDetail(ingredient_id, lang = 'en') {
    let response = await axios.get(ENDPOINT + `foods/${ingredient_id}/impact?lang=${lang}`);
    if(response) return response.data;
}

async function doPutIngredientsWeights(dish_id, ingredients, lang='en') {
    return await API.put(`envimpact/restaurants/1/courses/1/dishes/${dish_id}/ingredients/weights?lang=${lang}`, {ingredients: [...ingredients]});
}

export {
    doGetCompoundIngredients,
    doGetIngredientImpactDetail,
    doPutIngredientsWeights,
}