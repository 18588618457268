import React from 'react';
import LanguageSelect from "../../utility/LanguageSelect";
import {useAuth} from "../../../utils/contexts/auth-context";
import {useLocation, useNavigate} from "react-router-dom";

const Header = () => {

    const navigate = useNavigate();
    const {loggedIn} = useAuth()
    const location = useLocation();

    const handleClick = () => {
        navigate('/');
    }

    const getTitle = () => {
        switch (location.pathname) {
            case '/gdo':
                return 'GDO Page'
            case '/off_data':
                return 'Open Food Facts data'
            default:
                return 'MyCIA Environmental Impact';
        }
    };

    return (
        <div className="flex flex-row justify-center sm:justify-start p-4 bg-blue-600 ">
            <div className="font-bold text-white text-2xl cursor-pointer text-center"
                 onClick={handleClick}>
                {getTitle()}
            </div>
            {/*{loggedIn &&*/}
            {/*    <div className="absolute top-5 right-5">*/}
            {/*        <LanguageSelect/>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
};

export default Header;
