import {API} from "./api_config";


async function doGetIngredients(barcode, lang = 'en') {
    return await API.get(`products/envimpact/${barcode}/ingredients`);
}

async function doGetOffData(barcode, lang = 'en') {
    return await API.get(`products/off/${barcode}`);
}

function doPostOffData(barcode, data, lang = 'en') {
    return API.postEncoded(`products/off/${barcode}`, data);
}


export {
    doGetIngredients, doGetOffData, doPostOffData
}
