import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../utils/contexts/auth-context";
import Panel from "../UI/Panel";
import BaseInput from "../UI/BaseInput";
import Button from "../UI/Button";
import SubTitle from "../UI/Text/SubTitle";
import {doGetWhoAmI, doPostLogin} from "../../logic/auth";
import {doGetFoods} from "../../logic/foods";
import {useApi} from "../../utils/hooks/api";

const LoginForm = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {login} = useAuth();


    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        let response = await doPostLogin(user, password);
        if (response) {
            if (response.token) {
                login();
                sessionStorage.setItem('@auth_token', response.token);
                navigate('/gdo');
            }
        }
    }

    return (
        <Panel>
            <SubTitle className="mb-2">Insert your credentials</SubTitle>
            <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <BaseInput value={user}
                           onChange={(e) => setUser(e.currentTarget.value)}
                           required={true}
                           placeholder={t('login.Name')}/>
                <BaseInput value={password}
                           type='password'
                           onChange={(e) => setPassword(e.currentTarget.value)}
                           required={true}
                           placeholder={t('login.Password')}/>

                <Button type='submit' className="mt-2">{t('login.Login')}</Button>
            </form>
        </Panel>
    );
};

export default LoginForm;
