import {useToast} from "./toast-hook";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

function useApi() {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {displayError, displayWarning} = useToast();

    async function call(func, onSuccess, onFailure) {
        setLoading(true);

        try {
            let initial_response = await func();
            if (initial_response && initial_response.data) {
                const {data} = initial_response;
                if (data && onSuccess) {
                    onSuccess(data);
                }
            } else if (initial_response.response.status === 403) {
                await handleAuthFailure();
            } else {
                displayWarning('Network error. Try again');
                onFailure && onFailure();
            }
        } catch (error) {
            // Handle any errors here
            console.error('An error occurred:', error);
            throw new Error('An error occurred during the function execution.');
        } finally {
            setLoading(false);
        }
    }


    async function handleAuthFailure(message = 'Token expired. Enter your credentials') {
        displayError(message);
        navigate('/login');
    }

    return {
        call,
        loading,
    }
}

export {
    useApi,
}

