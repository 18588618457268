import React, {useState} from "react";
import {useModalStatus} from "./modal_status_hook";

const DishContext = React.createContext(null);

function useDishContextValue() {
    const [dish, setDish] = useState(null);
    const [offData, setOffData] = useState(null);
    const [allergens, setAllergens] = useState([]);
    const [diets, setDiets] = useState([]);
    const [impact, setImpact] = useState(null);
    const [barcode, setBarcode] = useState(null);

    const [weightModalStatus, openWeightModal, closeWeightModal] = useModalStatus();
    const [nutritionalModalStatus, openNutritionalModal, closeNutritionalModal] = useModalStatus();

    const updateIngredients = (ingredients) => {
        setDish({
            ...dish,
            ingredients: [...ingredients]
        });
    }

    const getDishWeight = React.useCallback(() => {
        if (dish && dish.ingredients) {
            let sum = 0;
            const finalSum = dish.ingredients.reduce((prev, ingredient) => {
                if (ingredient.weight)
                    return parseFloat(prev) + parseFloat(ingredient.weight)
                else return parseFloat(prev);
            }, sum);
            return finalSum;
        } else return 0;
    }, [dish]);

    const ingredients_weight = getDishWeight();

    return React.useMemo(() => {
        return {
            dish,
            setDish,
            updateIngredients,
            ingredients_weight,
            diets,
            setDiets,
            getDishWeight,
            allergens,
            setAllergens,
            impact,
            setImpact,
            weightModalStatus,
            openWeightModal,
            closeWeightModal,
            nutritionalModalStatus,
            openNutritionalModal,
            closeNutritionalModal,
            offData,
            setOffData,
            barcode,
            setBarcode
        }
    }, [
        dish, setDish, updateIngredients,
        diets, setDiets, getDishWeight,
        allergens, setAllergens,
        impact, setImpact,
        weightModalStatus, openWeightModal, closeWeightModal,
        nutritionalModalStatus, openNutritionalModal, closeNutritionalModal, offData, setOffData, barcode, setBarcode
    ])

}

function DishProvider({...props}) {
    const value = useDishContextValue();
    return <DishContext.Provider value={value} {...props}>{props.children}</DishContext.Provider>
}

function useDish() {
    const context = React.useContext(DishContext);
    if (!context) throw new Error('useDish must be used within a DishProvider');
    return context;
}

export {
    useDish,
    DishProvider,
}
